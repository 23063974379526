<template>
  <div class="box">
        <div style="font-size:20px">数据来源：微信小程序统计</div>
        <div>
            <Tooltip :content="tipText" style="">
                <Icon color="#336DFF" size="15" type="ios-alert-outline" />
                <span style="margin-right:20px">数据项解释</span>
            </Tooltip>
            <Timedropdown :disabled='disabled' @changeTime='getTime' ></Timedropdown>
        </div>

    </div>
</template>

<script>
import Timedropdown from '../components/timedropdown.vue'
export default {
    components: {
        Timedropdown
    },
    props:['tipText'],
    methods:{
        getTime(val){
            this.$emit('getDate',val)
        }
    }
}
</script>

<style scoped lang="less">
    .box{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    padding: 20px 68px 20px 20px;
    box-sizing: border-box;
    line-height: 40px;
}
</style>