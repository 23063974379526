<template>
    <span style="">
        <DatePicker 
            :clearable="false"
            format="yyyy-MM-dd" 
            type="daterange" 
            placement="bottom-end" 
            placeholder="Select date" 
            :value="searchTime"
            @on-change="getData"
            style="width: 200px">
        </DatePicker>
        <Dropdown trigger="custom" on-clickoutside :visible="visible" style="margin-left:55px">
            <a href="javascript:void(0)" @click="handleOpen" :class="disabled?'disabled':''">
                {{!visible?'选择时间':'取消选择'}}
            </a>
            <DropdownMenu slot="list">
                <Button @click="getTimes(1)" >昨日</Button><Button @click="getTimes(2)">前日</Button>
                <Button @click="getTimes(3)" :disabled='disabledW'>本周</Button><Button @click="getTimes(4)">上周</Button>
                <Button @click="getTimes(5)" :disabled='disabledM'>本月</Button><Button @click="getTimes(6)">上月</Button>
                <Button @click="getTimes(7)" :disabled='disabledQ'>本季度</Button><Button @click="getTimes(8)">上季度</Button>
                <Button @click="getTimes(9)">过去7天</Button><Button @click="getTimes(10)">过去30天</Button>
                <Button @click="getTimes(11)">过去90天</Button><Button @click="getTimes(12)">今年至今</Button>
            </DropdownMenu>
        </Dropdown>
    </span>
    
</template>

<script>
export default {
    props:{
        disabled:{
            disabled:false
        }
        // visible:{
        //     type:Boolean,
        //     default:()=>{
        //         return false
        //     }
        // }
    },
    data () {
        return {
            visible:false,
            disabledW:false,
            disabledM:false,
            disabledQ:false,
            searchTime:''
        }
    },
    created(){
        if( new Date().getDay() == 1 )
        this.disabledW = true
        let nowDay1 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd").split('-')[2]
        if( nowDay1 == '01'  )
        this.disabledM = true
        let nowDay2 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd").slice(5)
        if(nowDay2=='01-01'&&nowDay2=='04-01'&&nowDay2=='07-.1'&&nowDay2=='10-01')
        this.disabledQ = true

    },
    methods:{
        handleOpen(){
            if(!this.disabled){
                    if(this.visible){
                this.visible = false
                }
                else{
                    this.visible = true
                }
            }
            
            
        },
        getData(val){
            this.visible = false
            this.searchTime = val
            this.$emit('changeTime',val)
        },
        getTimes(type){
            let startTime = ''
            let endTime = this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd")
            if(type == 1){ // 昨天
                startTime = this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd")
            }else if(type == 2){ // 前天
                startTime = this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*2), "yyyy-MM-dd")
                endTime = this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*2), "yyyy-MM-dd")
            }else if(type == 3){ // 本周
                startTime = this.getWeek(0)
            }else if(type == 4){ // 上周
                startTime = this.getWeek(7)
                endTime = this.getWeek(1)
            }else if(type == 5){ // 本月
                startTime = this.getMonthStartDate()
            }else if(type == 6){ // 上月
                startTime = this.getLasMonth(1)
                endTime = this.getLasMonth(2)
            }else if(type == 7){ // 本季度
                startTime = this.getNowQuarter()
            }else if(type == 8){ // 上季度
                startTime = this.getLastQuarter(1)
                endTime = this.getLastQuarter(2)
            }else if(type == 9){ // 过去七天
                startTime = this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*8), "yyyy-MM-dd")
            }else if(type == 10){ //过去三十天
                startTime = this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*31), "yyyy-MM-dd")
            }else if(type == 11){ // 过去九十天
                startTime = this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*91), "yyyy-MM-dd")
            }else if(type == 12){ // 今年至今
                let data12 = new Date()
                let year12 = data12.getFullYear()
                startTime = `${year12}-01-01`
            }
            
            // this.timeData = JSON.parse(JSON.stringify(this.timeData))
            let timeData = []
            timeData[0] = startTime
            timeData[1] = endTime
            this.searchTime = timeData
            this.$emit('changeTime',timeData)

            this.visible = false
        },
        getNowQuarter(){
            let nowMonth = new Date().getMonth()
            let month = 0
            if(nowMonth >= 0 && nowMonth <3){
                month = 1
            }else if(nowMonth >= 3 && nowMonth <6){
                month = 4
            }else if(nowMonth >= 6 && nowMonth <9){
                month = 7
            }else if(nowMonth >= 9 && nowMonth <12){
                month = 10
            }
            let year = new Date().getYear()
            year += (year < 2000)?1900:0
            return `${year}-${month}-01`
        },
        getLastQuarter(time){
            let nowMonth = new Date().getMonth()
            let statrMonth = 0
            let endMonth = 0
            let endDay = 0
            let year = new Date().getYear()
            year += (year < 2000)?1900:0
            if(nowMonth >= 0 && nowMonth <3){
                statrMonth = 10
                endMonth = 12
                endDay = 31
                year--
            }else if(nowMonth >= 3 && nowMonth <6){
                statrMonth = 1
                endMonth = 3
                endDay = 31
            }else if(nowMonth >= 6 && nowMonth <9){
                statrMonth = 4
                endMonth = 6
                endDay = 30
            }else if(nowMonth >= 9 && nowMonth <12){
                statrMonth = 7
                endMonth = 9
                endDay = 30
            }
            if(time == 1){
                if(statrMonth<10)
                statrMonth = `0${statrMonth}`
                return `${year}-${statrMonth}-01`
            }else if(time == 2){
                if(endMonth<10)
                endMonth = `0${endMonth}`
                return `${year}-${endMonth}-${endDay}`
            }            
        },
        getMonthStartDate(){   
            let year = new Date().getYear()
            year += (year < 2000)?1900:0
            let month = new Date().getMonth()+1
            return `${year}-${month}-01`
        },
        getLasMonth(time){
            let nowdays = new Date();
            let year = nowdays.getFullYear();
            let month = nowdays.getMonth();
            if(month==0){
                month = 12;
                year = year-1;
            }
            if(month<10){
                month = '0'+month;
            }
            let myDate = new Date(year,month,0);
            if(time == 1){
                return year+'-'+month+'-01';//上个月第一天
            }else if(time == 2){
                return year+'-'+month+'-'+myDate.getDate();//上个月最后一天
            }
        },
        getWeek(n){  0
            let now=new Date();
            let year=now.getFullYear();    
            //因为月份是从0开始的,所以获取这个月的月份数要加1才行
            let month=now.getMonth()+1;     
            let date=now.getDate();         
            let day=now.getDay();           
            //判断是否为周日,如果不是的话,就让今天的day-1(例如星期二就是2-1)
            if(day!==0){
                n=n+(day-1);
            }
            else{
                n=n+6;       
            }
            if(day){
            //这个判断是为了解决跨年的问题
                if(month>1){
                    month=month;
                }
            //这个判断是为了解决跨年的问题,月份是从0开始的
                else{
                    year=year-1;
                    month=12;
                }
            }
            now.setDate(now.getDate()-n);
            year=now.getFullYear();
            month=now.getMonth()+1;
            date=now.getDate();
            let res =year+"-"+(month<10?('0'+month):month)+"-"+(date<10?('0'+date):date);
            return res;
        },
    }
}
</script>
 
<style lang = "less" scoped>
    .ivu-dropdown{
    line-height: 32px;

    /deep/.ivu-select-dropdown{
        top: 82px !important;
        right: -20px !important;
        height: 263px !important;
        width: 166px !important;
        padding: 0 !important;
    }
    /deep/.ivu-btn{
        width: 80px !important;
        height: 43px;
        line-height: 43px;
        margin:  0 1px 1px 0;
        border: none;

    }
    .disabled{
        cursor:wait 
    }
}
</style>